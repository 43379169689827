export default {
    params: {
       transType0: "Ware auf Lager",
       transType1: "Vertraglich",
       directionType0: "Einkaufen",
       directionType1: "Erreichen (ein Preis in einer Auktion)",
       lockStateType0: "läuft",
       lockStateType1: "Geschlossen",
       lockStateType2: "Abgesagt",
    },
    common: {
       cardMain: "Front Side",
       cardBack: "Back",
       amount: "Amount",
       voucher: "Recharge Voucher",
       withdraw1: "Auszahlungskonto",
       withdraw2: "Typ auswählen",
       remark: "Bemerkungen",
       verify:"Akkreditierung",
       verifySuccess:"Akkreditierung bestanden",
       onlineService: "Kundenbetreuung",
       tip1: "Bitte geben Sie den richtigen Betrag ein",
       tip2: "Unzureichender Kontostand",
       confirm: "Definieren.",
       cancel: "Stornierungen",
       tip: "Auf etw. aufmerksam machen",
       register: "Einschreibung",
       viewMore: "Mehr sehen",
       trade: "Geschäfte",
       empty: "Zur Zeit kein Inhalt",
       searchPairs: "Währungspaare suchen",
       and: "Im Gesang antworten",
       next: "Der nächste Schritt",
       success: "Erfolge",
       more: "Mehr",
       main: "Hauptbild",
       sub: "Bild",
       copySuccess: "Erfolg kopieren！",
       copyError: "Fehlgeschlagene Fortpflanzung！",
       view: "Auschecken",
       all: "Vollständig",
       goSet: "Gehen Sie vor und stellen Sie",
       available: "Benutzerfreundlichkeit",
       confirmCancel: "Sind Sie sicher, dass Sie absagen wollen?？",
       copy: "Eine Kopie machen von",
       submit: "Vorlegen (einen Bericht usw.)",
       send: "Senden",
       keep: "Weiter Bestellungen aufgeben",
       revenue: "Erwartete Erträge",
    },
    mine: {
       lastTime: "Letzte Anmeldung",
    },
    head: {
       mineInvite: "Freunde einladen",
       mineAddress: "Adresse für die Rücknahme",
       mine: "Kontocenter  ",
       orderCoin: "Spotauftrag",
       orderContract: "Vertragsauftrag",
       orderSecond: "Optionsaufträge",
       order: "(Kauf-)Bestellung",
       assetsWithdraw: "Geld abheben",
       assetsRecharge: "Münzeinwurf",
       assetsTotal: "Gesamtabschreibung der Vermögenswerte",
       assets: "Verbindlichkeiten",
       logout: "Erscheinen (in einer Zeitung usw.)",
       menuTit1: "Die aktuelle Marktsituation",
       menuTit2: "Geschäfte",
       menuTit3: "Finanzverwaltung",
       menuTit4: "Neue Münzen im Angebot",
       menuTit5: "Contest",
       menuTit6: "Event",
       menuTit7: "Blog (Lehnwort)",
       menuTit8: "Discover",
       menuItem0: "Optionshandel",
       menuItem1: "Münzhandel",
       menuItem2: "Vertragshandel",
       menuItem3: "Launchpad",
       menuItem4: "Abstimmung über Münze",
       menuItem5: "Livestream",
       menuItem6: "Offline Event",
       login: "Sich anmelden",
       register: "Einschreibung",
       download: "Code zum Herunterladen scannen",
       noPermissions: "Ihre IP-Adresse zeigt die Länder an, in denen die Dienste von ICESAS eingeschränkt sind.",
    },
    home: {
       h1: "The First Choice For The Initial Launch",
       p1: "Trade over 50 cryptocurrencies on ICESAS",
       tradeTab1: "Münzhandel",
       tradeTab2: "Vertragshandel",
       tradeTag1: "Anstieg und Rückgang der Aktie an einem Handelstag",
       tradeTag2: "Heiße Münzen",
       tradeTag3: "Prime",
       serviceTit: "Eine breite Palette von Dienstleistungen zur Auswahl",
       serviceTit1: "Kassageschäft",
       servicedesc1: "Kauf, Verkauf oder Handel von Kryptowährungen zum aktuellen Marktpreis.",
       serviceTit2: "Unbefristeter Vertrag",
       servicedesc2: "Handel mit Verträgen durch Long- oder Short-Positionen",
       serviceTit3: "Finanzverwaltung",
       servicedesc3: "Ein-Klick-Pfand zur Erhöhung des Tageseinkommens",
       serviceTit4: "Prime",
       servicedesc4: "Neue Qualitätsmünzen abonnieren",
       liveTit: "ICESAS Live Streaming",
       whyTit: "ICESAS Live Streaming",
       whyTit1: "Sicher und vertrauenswürdig",
       whySubtit1: "Internes Clearing, Auditing und Risikomanagement in Echtzeit Cold Wallets werden von Multi-Signatur-Wallets getrennt, um Gelder zu schützen Professionelle Audits von Drittanbietern, professionelle Audits von Sicherheitsorganisationen",
       whyLink: "Alle Sicherheitsmechanismen anzeigen",
       whyTit2: "Leistungsstarke Aggregationsmaschine für Geschäfte",
       whySubtit2: "Aggregations-Engine mit extrem niedriger Latenz, enormer Durchsatz, kein Jitter bei extremen Spitzenwerten, keine Ausfallzeiten bei Spitzenwerten",
       whyTit3: "Vertrauenswürdige Plattformen",
       whySubtit3: "Als eine der 50 größten Handelsplattformen der Welt hält sich ICESAS strikt an die Regeln der Branche und nimmt nicht am Marktgeschehen teil.",
       whyTit4: "Schnelles Handelssystem",
       whySubtit4: "ICESAS bietet Ihnen eine einfache und übersichtliche Handelsschnittstelle und einen bequemen Betriebsprozess, der es Ihnen ermöglicht, digitale Vermögenswerte bequemer zu handeln.",
       guideTit: "Leitfaden für Einsteiger",
       guideh1: "Ein ICESAS-Konto erstellen",
       guidep1: "Melden Sie sich jetzt an und beginnen Sie Ihre Handelsreise!",
       guideBtn1: "Einschreibung",
       guideh2: "Geld auf einer Bank sparen",
       guidep2: "Schnelles Aufladen und einfache Gutschrift!",
       guideBtn2: "Münzeinwurf",
       guideh3: "Aufnahme des Handels",
       guidep3: "Beginnen Sie zu handeln Beginnen Sie zu handeln und genießen Sie die unendlichen Möglichkeiten!",
       guideBtn3: "Münzhandel",
       readyTop1: "Sind Sie bereit für den Start?？",
       readyMid: "ICESAS verfügt über fortschrittliche Technologie, um Ihnen ein schnelles Kauf-, Verkaufs- und Handelserlebnis zu bieten. Handeln Sie sicher mit unserer einfachen Plattform nur für Sie gebaut.",
       readyTit: "Scan to download APP",
       readySubtit: "Android and iOS supported",
       readyDownload: "Download on the",
       readyAppStore: "AppStore",
       readyGetin: "GET IT ON",
       readyGoole: "Google Play",
       faqTit: "FAQ",
       faqTit1: "Erfahrung mit der Finanzierung",
       faqCont1: "Futures Credit ist ein digitaler Geldverleih-Bonus für ICESAS-Benutzer, um ihnen den Handel mit ICESAS-Perpetualkontrakten zu erleichtern. Im Vergleich zum traditionellen Futures-Handel von ICESAS kann Futures Credit in Kombination mit dem vom Nutzer bereitgestellten Kapitalbetrag an Krypto-Vermögenswerten als relativ große Eröffnungsmarge für den Futures Credit-Handel verwendet werden.",
       faqTit2: "Was ist CoinBox?",
       faqCont2: "Futures CoinBox ist eine neue Produktfunktion von ICESAS, die es Ihnen ermöglicht, eine CoinBox mit Kryptowährungen an einen Freund zu senden. Wenn Ihr Freund sein CoinBox-Passwort über die ICESAS-App eingibt, um das Geschenk zu erhalten, erhält er einen zufälligen Betrag an Kryptowährung aus der CoinBox.Kredit kann als relativ große Marge für die Eröffnung von Positionen im Futures-Kredithandel verwendet werden.",
       faqTit3: "Leitfaden zum Geldverdienen für Anfänger",
       faqCont3: "Futures Coinstores neuestes Kryptowährungs-Vermögensverwaltungsprodukt wird von Coinstores quantitativem Team entwickelt, das aus erfahrenen quantitativen Investmentfondsmanagern, Finanzeliten aus der Futures- und Wertpapierbranche und BAT-Technologen besteht. Das Team wurde 2021 in Singapur gegründet und konzentriert sich auf die quantitative Verwaltung von digitalen Vermögenswerten mit professionellen quantitativen Modellierungslösungen und Managementfähigkeiten.Kredit kann als relativ große Eröffnungsmarge im Futures-Kredithandel verwendet werden.",
       faqTit4: "Seien Sie live dabei im ICESAS",
       faqCont4: "Futures Live ist ein Live-Streaming-Produkt, bei dem Top-Speicher-Blockchain-Projekte und Branchenexperten die neuesten und heißesten Vorträge und Präsentationen halten. Schließen Sie sich uns an, um mehr zu erfahren.Kredit kann als relativ große Eröffnungsmarge im Futures-Kredithandel verwendet werden.",
       faqTit5: "Wie man Kryptowährungen bei ICESAS kauft",
       faqCont5: "Futures ICESAS bietet verschiedene Drittanbieter für den Kauf von Kryptowährungen für Benutzer weltweit. Siehe unseren Artikel über den Kauf von Kryptowährungen.credit kann als relativ große Eröffnungsmarge für Futures Credit Trades verwendet werden.",
       partnerTit: "Kooperativer Partner",
    },
    assets: {
       tit: "Gesamtvermögen",
       recharge: "Münzeinwurf",
       withdraw: "Geld abheben",
       transfer: "Übertragung",
       tab1: "Gesamtvermögen",
       tab2: "Münzkonto",
       tab3: "Vertragskonto",
       tab4: "Vertragliche quantitative Rechnung (CQA)",
       tab5: "Finanzkonto",
       assetsTotal: "(Gesamt-)Summe",
       assetsReal: "Benutzerfreundlichkeit",
       assetsFrozen: "Einfrieren (Darlehen, Lohn, Preis usw.)",
       proportion: "Anteil am Vermögen",
       tip1: "Mindestabhebungsbetrag",
       tip2: "Abhebungen, die unter dem Mindestbetrag liegen, werden nicht gutgeschrieben und können nicht zurückerstattet werden.",
       tip3: "Mindestabhebungsbetrag",
       linkType: "Typ der Kette",
       rechargeAddress: "Adresse aufladen",
       contractAddress: "Vertragsadresse",
       rechargeRecord: "Münzeinfüllprotokoll",
       tip4: "Sie haben die Passworteinstellung nicht abgeschlossen, das Aufladen von Münzen wird vorerst nicht unterstützt",
       withrawAddress: "Adresse für die Rücknahme",
       placeWithrawAddress: "Bitte geben Sie die Abmeldeadresse ein",
       withrawAmount: "Barabhebung",
       placeWithrawAmount: "Bitte geben Sie den Abhebungsbetrag ein",
       availableLimit: "Verfügbarer Kredit",
       fee: "Bearbeitungsgebühr",
       expectedReceipt: "Voraussichtliche Ankunft",
       withdrawRecord: "Aufzeichnungen über Münzabhebungen",
    },
    login: {
       login: "Sich anmelden",
       register: "Einschreibung",
       email: "Posteingang",
       phone: "Handys",
       forget: "Passwort vergessen?？",
       noAccount: "Kein Konto？",
       haveAccount: "Sie haben bereits ein Konto？",
       agree1: "Ich habe verstanden.",
       agree2: "Nutzungsvereinbarung",
       agree3: "Erklärung zum Datenschutz",
       resetPassword: "Passwort zurücksetzen",
       securityTip: "Um die Sicherheit Ihres Vermögens zu gewährleisten, können Sie 24 Stunden nach der Passwortänderung keine Münzen abheben.",
    },
    form: {
       send: "CAPTCHA",
       labelCaptcha: "CAPTCHA, eine Art von Challenge-Response-Test (Computertechnik)",
       errorCaptcha: "Bitte geben Sie den Verifizierungscode ein",
       labelEmail: "Posteingang",
       errorEmail: "Bitte geben Sie die korrekte E-Mail Adresse ein",
       placeEmail: "Bitte geben Sie Ihre E-Mail Adresse ein",
       labelPhone: "Handys",
       errorPhone: "Bitte geben Sie die richtige Handynummer ein",
       placePhone: "Bitte geben Sie Ihre Mobiltelefonnummer ein",
       labelPassword: "kryptographisch",
       errorPassword: "Bitte geben Sie das richtige Passwort ein",
       placePassword: "Bitte geben Sie Ihr Passwort ein",
       labelInvite: " Einladungscode (optional)",
       errorInvite: "Bitte geben Sie den Einladungscode ein",
       placeInvite: "Bitte geben Sie den Einladungscode ein",
       placeSelece: "Bitte auswählen",
       account_number: "Kontonummer",
       label_type: "Typ auswählen",
       place_type: "Bitte Typ auswählen",
       label_number: "Kontonummer",
       place_number: "Kontonummer",
       wallet_address: 'Adresse der Brieftasche',
       bank_name: 'Name der Bank',
       bank_address: "Adresse der Bank",
       name: "Vorname und Nachname",
       placeName: "Bitte geben Sie einen Namen ein",
       payee_address: 'Adresse des Zahlungsempfängers',
       swift_code: "Swift/Swift Code/BIC",
       aba: "ABA",
       branch_number: "Name der Niederlassung",
       branch_code: "Zeilencode",
       bank_code: "Bankleitzahl",
       phone: "Mobiltelefonnummer",
       iban: "IBAN",
       sort_code: "Sort Code",
       placeInner: "Bitte Inhalt eingeben",
       labelCurrency: "Währungstyp",
       placeCurrency: "Bitte wählen Sie eine Währung",
       labelNumber: "Mengen",
       placeNumber: "Bitte geben Sie die Anzahl ein",
       messageNumber: "Bitte geben Sie die Anzahl ein",
       labelPrice: "Preise",
       placePrice: "Bitte geben Sie einen Preis ein",
       placeAmount: "Bitte geben Sie den Betrag ein",
       labelJoinMoney: "Höhe des Beitrittsbetrags",
       labelOldPassword: "Altes Passwort",
       placeOldPassword: "Bitte geben Sie das alte Passwort ein",
       messageOldPassword: "Bitte geben Sie das alte Passwort ein",
       psdTip: "Das Passwort muss eine Kombination aus 6 ~ 16 Bit Buchstaben + Ziffern sein",
       labelNewPassword: "Neues Passwort",
       placeNewPassword: "Bitte geben Sie ein neues Passwort ein",
       messageNewPassword: "Bitte geben Sie ein neues Passwort ein",
       labelCrmPassword: "Bestätigen Sie Ihr Passwort",
       placeCrmPassword: "Bitte geben Sie Ihr Passwort erneut ein",
       messageCrmPassword: "Bitte geben Sie Ihr Passwort erneut ein",
       labelCountry: "Land/Gebiet",
       placeCountry: "Bitte wählen Sie ein Land",
       messageCountry: "Bitte wählen Sie ein Land",
       labelId: "Identifikationsnummer",
       messageId: "Bitte ID-Nummer eingeben",
       labelCaptchaEmail: "Der Verifizierungscode wird gesendet an",
       labelTotalAmount: "Summe (Betrag oder Wert)",
    },
    table: {
       labelName: "Name (einer Sache)",
       labelLastPrice: "Neuester Preis",
       labelRang: "24H Preisanstieg oder -verfall",
       labelVolume: "24H Umsatz",
       labelVolumeNum: "24H Umsatz",
       labelKline: "K Strichzeichnung",
       labelHeight: "24H Hoch",
       labelLow: "24H Niedrig",
       labelOperate: "Rigg",
       labelTime: "Mal",
       labelCurrency: "Währungstyp",
       labelAmount: "Geldsumme",
       labelState: "Stand der Dinge",
       state0: "Fertig",
       state1: "Scheitern (z. B. bei Experimenten)",
       state2: "Abgesagt",
       withdrawState0: "In Erwartung der Genehmigung",
       withdrawState1: "Fertig",
       withdrawState2: "Abgelehnt",
       labelDirection: "Ausrichtungen",
       labelPrice: "Preise",
       labelLever: "Hebeleisen",
       labelHangNum: "Ordnungszahl",
       gmtCreate: "Erstellungszeit",
       operate: "Rigg",
       labelPeriod: "Abrechnungszyklus",
       every: "Jede",
       hour: "Stunden",
       day: "Tag",
       labelFundRate: "Förderquote",
       labelDailyRate: "Finanzierungssatz am selben Tag",
       labelLevesCurrency: "Anlagewährung",
       labelReturn: "Annualisierte Rendite",
       labelLevesTerm: "Investitionszeitraum",
       labelStartAmount: "Ausgangsbetrag",
       viewDetails: "Details anzeigen",
       labelPairs: "Transaktionsgegenwert",
       labelType: "Typologie",
       labelEntrustNumber: "Anzahl der Provisionen",
       labelTransNumber: "Anzahl der Transaktionen",
       labelTotalAmount: "Gesamtbetrag der Transaktion",
       labelEntrustTime: "Inbetriebnahmezeit",
       labelBuyPrice: "Angebotspreis",
       labelBuyNumber: "Kaufmenge",
       labelBuyTime: "Zeitpunkt des Kaufs",
       labelSellPrice: "Verkaufskurs",
       labelProfitLoss: "Wachsend und abnehmend",
       labelNumber: "Mengen",
       labelMinerTitle: "Finanzieller Name",
    },
    foot: {
       menuTit1: "Über uns",
       menuTit2: "Kontakt",
       menuTit3: "Ressource (z. B. Arbeitskräfte oder Tourismus)",
       menuTit4: "Hand",
       menuTit5: "Geschäfte",
       menuItem1: "Münzshop-Team",
       menuItem2: "Authentifizierungskanal",
       menuItem3: "Geschäftliche Einzugsermächtigung",
       menuItem4: "Mitarbeiterin",
       menuItem5: "Über ICESAS",
       menuItem6: "Nutzungsvereinbarung",
       menuItem7: "Offizielle E-Mail",
       menuItem8: "ICESAS-Verträge",
       menuItem9: "Anwendung von Münzen",
       menuItem10: "Die aktuelle Marktsituation",
       menuItem11: "Münzhandlung Akademie",
       menuItem12: "Standardtarife",
       menuItem13: "Abhebungsgebühr und Abhebungsgrenze",
       menuItem14: "Währung Beschreibung",
       menuItem15: "Bürgschaft",
       menuItem16: "Ausfüllen und Zurückziehen des Status",
       menuItem17: "Vorschläge und Feedback",
       menuItem18: "Hilfe-Center",
       menuItem19: "Einreichung von Arbeitsaufträgen",
       menuItem20: "API-Dokumentation",
    },
    market: {
       spots: {
          filterTab1: "Selbstbedienung",
          filterTab2: "Münzen Zitate",
          filterTab3: "Vertragsmarkt",
          filterTab4: "Launchpad",
          tabAll: "Vollständig",
          tab1: "Hauptbereich",
          tab2: "Neue Wege beschreiten",
          tab3: "Offene Fläche",
          tab4: "Prime",
          tab5: "Münzen",
          tab6: "Vertraglich",
          tab7: "USDT Für immer",
          tab8: "Volle Währung Unbefristet",
       }
    },
    trade:{
       chartMsg: "Informationen zur Währung",
       marketPrice:"Marktwert",
       limitPrice:"Preisobergrenze",
       marketPriceTip: "Marktbestellungen werden zum besten Marktpreis gekauft und verkauft, den es zu diesem Zeitpunkt gibt.",
       lever: "Hebeleisen",
       handNum: "Ordnungszahl",
       margin: "Einschuss (im Derivatehandel)",
       assets:"Verbindlichkeiten",
       balance:"Salden",
       book: "Kommissionsbuch",
       quantity: "Kumulierter Betrag",
       navState0: "Warten auf die Eröffnung einer Position",
       navState1: "Derzeitige Position",
       navState2: "Historische Kommissionen",
       navState3: "Laufende Kommissionen",

       fund: {
          tit1: "Vertragsmarkt",
          tit2: "Historie der Refinanzierungssätze",
          p1: "Ein Versicherungsfonds, der verhindern soll, dass die Positionen der Anleger automatisch reduziert werden. Der Fonds dient dazu, den Preis nicht ausgeführter geschlossener Mandate zu verbessern, um zu verhindern, dass sie vom automatischen Positionsabbausystem übernommen werden.",
          p2: "Der Anstieg der Versicherungssumme stammt aus starken Pauschalmandaten, die auf dem Markt zu einem besseren Preis als dem Konkurspreis gehandelt werden.",
          p3: "Die aktuellen und historischen Salden der Versicherungsfonds sind nachstehend aufgeführt。",
          tab1: "USDT Für immer",
          tab2: "Volle Währung Unbefristet",
          contract: "Vertraglich",
       },
    },
    earn: {
       tit: "Ein-Klick-Pfand, täglich steigendes Einkommen",
       tab1: "Festbetragsfinanzierung",
       orderTit: "Finanzielle Anordnungen",
       labelTotalApr: "Annualisierte Rendite",
       labelTimeLimit: "Projektzyklus",
       labelMoney: "Höhe des Beitrittsbetrags",
       labelType: "Payback-Methode",
       labelType1: "Schuldendienst bei Fälligkeit",
       labelNowBalance: "Aktuelle Bilanz",
    },
    invite: {
       tit: "Freunde einladen",
       p: "Scannen Sie diesen QR-Code, um Freunde einzuladen",
       iviteLink: "Einladungs-Link",
       registerCode: "Registrierungscode",
    },
    noPermissions: {
       tip: "Es tut uns leid, Ihr Gebiet ist vorübergehend Jiao Gesetz Verteidigung Woche diese Plattform Station, die schwarze Gesetz, um Dienstleistungen zu bewegen!",
    },
    order: {
       tit: "Datensatz bestellen",
       tab1: "Optionsaufträge",
       tab2: "Münzbestellungen",
       tab3: "Vertragsauftrag",
       tab4: "Finanzielle Anordnungen",
       filterType0: "Alle Typen",
       filterType1: "Fertig",
       filterType2: "Abgesagt",
       filterType3: "Derzeitige Position",
       filterDirection0: "Volle Bandbreite von Richtungen",
       filterDirection1: "Einkaufen",
       filterDirection2: "Erreichen (ein Preis in einer Auktion)",
    },
    personal: {
       tit1: "Sicherheitsstufe",
       tit2: "Anmeldekennwort",
       tit3: "Handels-Passwort",
       tit4: "Sicherheitseinstellungen",
       strong: "Starke",
       setting: "Aufstellen",
       settings: "Aufstellen",
       change: "Variation",
       idTip1: "Authentifizieren",
       idTip2: "Authentifizierung, bevor mit dem Rest des Vorgangs fortgefahren wird",
       withdrawTip1: "Adresse für die Rücknahme",
       withdrawTip2: "Bitte binden Sie die Abmeldeadresse",
       loginPsdTit: "Einstellen des Anmeldekennworts",
       withdrawPsdTit: "Festlegen eines Passworts für Abhebungen",
       idTit: "Echtnamen-Authentifizierung",
       phoneTit: "Handy-Klebung",
       phoneTip: "Keine Stornierung innerhalb von 24 Stunden nach dem Telefonwechsel",
       walletTit: "Verbindliche Brieftasche",
       accountNumber: "Kontonummer",
       addAddress: "Adresse hinzufügen",

    },
    contest: {
       hot: "Aktuelle Ereignisse",
    },
    city: {
		albania: "Albanien",
		algeria: "Algerien",
		argentina: "Argentinien",
		armenia: "Armenien",
		australia: "Australien",
		pakistan: "Pakistan",
		austria: "Österreich",
		bahrain: "Bahrain",
		belgium: "Belgien",
		bosnia_and_Herzegovina: "Bosnien und Herzegowina",
		brazil: "Brasilien",
		brunei: "Brunei",
		bulgaria: "Bulgarien",
		cambodia: "Kambodscha",
		canada: "Kanada",
		cameroon: "Kamerun",
		chile: "Chile",
		colombia: "Kolumbien",
		costa_Rica: "Costa Rica",
		croatia: "Kroatien",
		cyprus: "Zypern",
		czech_Republic: "Tschechische Republik",
		denmark: "Dänemark",
		dominican_Republic: "Dominikanische Republik",
		egypt: "Ägypten",
		estonia: "Estland",
		ethiopia: "Äthiopien",
		finland: "Finnland",
		france: "Frankreich",
		georgia: "Georgien",
		germany: "Deutschland",
		ghana: "Ghana",
		greece: "Griechenland",
		guyana: "Guyana",
		honduras: "Honduras",
		hong_Kong_China: "Hong Kong, China",
		hungary: "Ungarn",
		iceland: "Island",
		ireland: "Irland",
		italy: "Italien",
		india: "Indien",
		indonesia: "Indonesien",
		israel: "Israel",
		iran: "Iran",
		iraq: "Irak",
		japan: "Japan",
		kazakstan: "Kasachstan",
		kenya: "Kenia",
		korea: "Korea",
		kuwait: "Kuwait",
		kyrgyzstan: "Kirgisistan",
		laos: "Laos",
		latvia: "Lettland",
		lithuania: "Litauen",
		luxembourg: "Luxemburg",
		macao_China: "Macau, China",
		macedonia: "Mazedonien",
		malaysia: "Malaysia",
		malta: "Malta",
		mexico: "Mexiko",
		moldova: "Moldawien",
		monaco: "Monaco",
		mongolia: "Mongolei",
		montenegro: "Republik Montenegro",
		morocco: "Marokko",
		myanmar: "Myanmar",
		netherlands: "Niederlande",
		new_Zealand: "Neuseeland",
		nepal: "Nepal",
		nigeria: "Nigeria",
		norway: "Norwegen",
		oman: "Oman",
		palestine: "Palästina",
		panama: "Panama",
		paraguay: "Paraguay",
		peru: "Peru",
		philippines: "die Philippinen",
		poland: "Polen",
		portugal: "Portugal",
		puerto_Rico: "Puerto Rico",
		qatar: "Katar",
		romania: "Rumänien",
		russia: "Russland",
		republic_of_Trinidad_and_Tobago: "Republik Trinidad und Tobago",
		rwanda: "Ruanda",
		saudi_Arabia: "Saudi Arabien",
		serbia: "Serbien",
		singapore: "Singapur",
		slovakia: "Slowakei",
		slovenia: "Slowenien",
		south_Africa: "Südafrika",
		spain: "Spanien",
		sri_Lanka: "Sri Lanka",
		sweden: "Schweden",
		switzerland: "Schweiz",
		taiwan_China: "Taiwan, China",
		tajikistan: "Tadschikistan",
		tanzania: "Tansania",
		thailand: "Thailand",
		turkey: "Türkiye",
		turkmenistan: "Turkmenistan",
		ukraine: "Ukraine",
		united_Arab_Emirates: "Vereinigte Arabische Emirate",
		united_Kingdom: "Großbritannien",
		united_States: "Vereinigte Staaten",
		uzbekistan: "Usbekistan",
		venezuela: "Venezuela",
		vietnam: "Vietnam",
		afghanistan: "Afghanistan",
		angola: "Angola",
		azerbaijan: "Aserbaidschan",
		bangladesh: "Bangladesch",
		belarus: "Belarus",
		belize: "BelizeCity in Germany",
		benin: "Benin",
		bhutan: "Bhutan",
		bolivia: "Bolivia",
		botswana: "Botswana",
		british_Virgin_Islands: "Britische Jungferninseln",
		burkina_Faso: "Burkina faso",
		burundi: "Burundi",
		cape_Verde: "Kap Verde",
		cayman_Islands: "Kaimaninseln",
		central_African_Republic: "Zentralafrikanische Republik",
		chad: "Tschad",
		comoros: "Komoren",
		the_Republic_of_the_Congo: "Kongo (Brazzaville)",
		democratic_Republic_of_the_Congo: "Kongo (Kinshasa)",
		djibouti: "Dschibuti",
		ecuador: "Ecuador",
		el_Salvador: "El Salvador",
		equatorial_Guinea: "Äquatorialguinea",
		eritrea: "Eritrea",
		fiji: "Fidschi",
		gabon: "Gabun",
		gambia: "Gambia",
		greenland: "Grönland",
		guatemala: "Guatemala",
		guinea: "Guinea",
		haiti: "Haiti",
		isle_of_Man: "Insel Man",
		cote_d_Ivoire: "Elfenbeinküste",
		jamaica: "Jamaika",
		jordan: "Jordanien",
		lebanon: "Libanon",
		lesotho: "Lesotho",
		liberia: "Liberia",
		libya: "Libyen",
		madagascar: "Madagaskar",
		malawi: "Malawi",
		maldives: "Malediven",
		mali: "Mali",
		mauritania: "Mauretanien",
		mauritius: "Mauritius",
		mozambique: "Mosambik",
		namibia: "Namibia",
		nicaragua: "Nicaragua",
		republic_of_Niger: "Niger",
		north_Korea: "Nordkorea",
		reunion: "Réunion",
		san_Marino: "San Marino",
		senegal: "Senegal",
		sierra_Leone: "sierra leone",
		somalia: "Somalia",
		sudan: "Sudan",
		suriname: "Suriname",
		eswatini: "Eswatini",
		syria: "Syrien",
		togo: "Togo",
		tonga: "Tonga",
		tunisia: "Tunesien",
		united_tates_Virgin_Islands: "Amerikanische Jungferninseln",
		uganda: "Uganda",
		uruguay: "Uruguay",
		vatican: "Vatikan",
		yemen: "Jemen",
		yugoslavia: "Jugoslawien",
		zambia: "Sambia",
		zimbabwe: "Zimbabwe",
		china: "China",
	}
 };






